import api from '@apiInstance';

export default {
  add(data) {
    return api.post('/departments', data);
  },
  get(id) {
    return api.fetch('/departments/' + id);
  },
  list(params) {
    return api.fetch('/departments', { params });
  },
  update(data) {
    return api.patch('/departments', data);
  },
  delete(id) {
    return api.remove('/departments', id);
  },
};
